import React from 'react'
import Layout from '../../components/layout'

const Terms = () => {
  return (
    <Layout disableform classes={'bg-about'}>
      <div className='flex flex-col py-40 justify-center items-center'>
        <div className='lg:w-2/6 w-5/6 md:w-4/6'>
          <div className='flex flex-col gap-3 mb-8'>
            <h1 className='font-extrabold text-4xl'>TERMINOS DE USO Y CONDICIONES</h1>
            <p>Los términos y condiciones de uso regulan el acceso y uso del Sitio por parte de cualquier persona, denominada en adelante "usuario/s". El propósito de estos términos y condiciones es establecer las condiciones para acceder, navegar y utilizar el Sitio.</p>
            <p>Al acceder, utilizar o navegar por el Sitio, el usuario acepta cumplir y respetar estos términos y condiciones. Cualquier usuario que no esté de acuerdo con estos términos debe abstenerse de usar y navegar por el Sitio.</p>
            <p>Nos reservamos el derecho de modificar y/o actualizar estos términos y condiciones mediante la publicación de una nueva versión en nuestro Sitio. Se recomienda al usuario revisar periódicamente el Sitio para consultar los términos y condiciones actualizados.</p>
            <p>Para acceder al Sitio, el usuario debe cumplir con la edad legal requerida para el consumo y/o compra de alcohol según la legislación aplicable en su país de residencia. Si no hay legislación específica al respecto, se requiere tener al menos 21 años para acceder al Sitio.</p>
          </div>
          <div className='flex flex-col gap-4'>
            <div id='terminos_1' className='flex flex-col gap-4 mb-8'>
              <h2 className='font-bold text-2xl'>1. NAVEGACIÓN EN EL SITIO WEB</h2>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>1.1 ACCESO AL SITIO WEB</h3>
                <p>Los usuarios son responsables del hardware y el software necesarios para acceder a Internet y al Sitio. Nos reservamos el derecho, a nuestra discreción, de suspender o impedir el acceso total o parcial al Sitio, su contenido o servicios ofrecidos en el mismo, sin previo aviso y en la medida en que lo permita la ley. Esta suspensión y/o terminación no dará lugar a ninguna compensación para el usuario.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>1.2 ENLACES EXTERNOS</h3>
                <p>El Sitio puede contener enlaces a otros sitios web o fuentes de Internet. Estos sitios web y fuentes externas, incluido su contenido y cualquier servicio de terceros, están sujetos a sus propios términos y condiciones y políticas. No controlamos estos sitios y fuentes externas y no somos responsables del contenido, publicidad, productos, servicios u otros elementos disponibles en ellos.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>1.3 COMPORTAMIENTO DE LOS USUARIOS</h3>
                <p>Los usuarios deben garantizar que tienen la edad legal para el consumo y/o compra de bebidas alcohólicas según la legislación aplicable en su país. Deben utilizar el Sitio solo con fines lícitos y no comerciales, a menos que cuenten con nuestro consentimiento previo por escrito. Además, deben proporcionar datos personales precisos y actuales cuando corresponda.</p>
                <p>Se prohíbe a los usuarios realizar representaciones en nombre de terceros o en nombre de la Compañía para cualquier propósito. Deben comportarse de manera responsable, legal y respetuosa con los derechos de otros usuarios, la Compañía y terceros.</p>
                <p>Los usuarios deben abstenerse de cargar o enviar cualquier material que contenga virus informáticos u otros códigos diseñados para interrumpir o dañar el funcionamiento del Sitio. También deben abstenerse de perturbar el funcionamiento del Sitio o infringir cualquier requisito o norma relacionada.</p>
                <p>En caso de incumplimiento de estas obligaciones, nos reservamos el derecho de impedir el acceso al Sitio del usuario, además de cualquier otro recurso legal disponible.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>1.4 CONTENIDO GENERADO POR EL USUARIO</h3>
                <p>Los usuarios son responsables del contenido que publiquen en el Sitio y de las consecuencias de su difusión. No respaldamos dicho contenido y no seremos responsables por él. Nos reservamos el derecho de eliminar cualquier contenido que consideremos inapropiado.</p>
                <p>Los usuarios conservan sus derechos de propiedad intelectual sobre el contenido que publiquen en el Sitio. Sin embargo, al publicar dicho contenido, otorgan a la Compañía una licencia para usarlo en el Sitio según sea necesario.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>1.5 REPORTE DE CONTENIDO ILÍCITO</h3>
                <p>Los usuarios pueden notificar cualquier contenido inapropiado a través de nuestro formulario de contacto.</p>
              </div>
            </div>
            <div id='terminos_2' className='flex flex-col gap-4 mb-8'>
              <h2 className='font-bold text-2xl'>2. PROTECCIÓN DEL CONTENIDO DEL SITIO</h2>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>2.1 CONTENIDO DE LA COMPAÑÍA</h3>
                <p>El contenido del Sitio, incluidos textos, imágenes, software y otros elementos, está protegido por derechos de propiedad intelectual. El usuario no tiene derecho a reproducir o utilizar este contenido con fines comerciales.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>2.2 BASES DE DATOS</h3>
                <p>Las bases de datos del Sitio están protegidas por derechos de propiedad intelectual. Cualquier uso no autorizado de su contenido puede ser sancionado.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>2.3 MARCAS Y LOGOTIPOS</h3>
                <p>Las marcas y logotipos del Sitio son propiedad de la Compañía o terceros. Su reproducción o uso no autorizado está prohibido.</p>
              </div>
            </div>
            <div id='terminos_3' className='flex flex-col gap-2 mb-8'>
              <h2 className='font-bold text-2xl'>3. DATOS PERSONALES</h2>
              <p>El procesamiento de datos personales en el Sitio está sujeto a nuestra Política de Privacidad y Cookies.</p>
            </div>
            <div id='terminos_4' className='flex flex-col gap-2 mb-8'>
              <h2 className='font-bold text-2xl'>4. RESPONSABILIDAD</h2>
              <p>No podemos garantizar la precisión o integridad de la información en el Sitio. No seremos responsables por interrupciones del Sitio, errores informáticos, virus, inexactitudes en el contenido o cualquier daño directo o indirecto resultante del acceso al Sitio.</p>
            </div>
            <div id='terminos_5' className='flex flex-col gap-2 mb-8'>
              <h2 className='font-bold text-2xl'>5. LEY APLICABLE Y JURISDICCIÓN</h2>
              <p>Estos términos y condiciones se rigen por la ley Mexicana. Cualquier disputa relacionada con el Sitio estará sujeta a la jurisdicción de los tribunales nacionales ordinarios de Mexico.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Terms
