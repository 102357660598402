import React from 'react';
import { motion } from "framer-motion";

import { VelocityLogoVerticalWhite } from '../assets/liquors/velocity';
import CountDown from './ui/countdown';

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const VelocityLayout = () => {
  return (
    <motion.div
      className='
        flex flex-col
        items-center justify-center
        min-h-screen
        bg-hero-vegasFilter bg-cover bg-no-repeat bg-center 2xl:bg-fixed
        '>
      <motion.div className='w-full h-full flex flex-col gap-8 justify-center items-center'>
        <motion.img
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] }
          }}
          src={VelocityLogoVerticalWhite} alt='velocity' className='h-auto w-5/6 lg:w-2/6' />

        <motion.div className='w-2/6 bg-white h-0.5'></motion.div>
        <motion.div className='text-white font-bold text-3xl'>Tequila, Jalisco</motion.div>
{/*         <CountDown
          targetDate={'2024-07-21T00:00:00'}
          classes='w-auto flex items-center justify-center pt-6
          text-white text-4xl flex-col'
        /> */}
      </motion.div>
    </motion.div>
  );
};

export default VelocityLayout;
