import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CardList from './card-list';

const NavItem = ({ href, text, currentPath, textwhite = false, dropdownItems }) => {
  const [t] = useTranslation('global');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <li>
      {dropdownItems ? (
        <div className='w-fit' onMouseEnter={handleDropdownToggle} onMouseLeave={handleDropdownToggle}>
          <Link
            to={'/products'}
            className={`
            ${currentPath === '/products' && 'text-gray-400'}
              text-xl relative ${textwhite ? 'after:bg-white' : 'after:bg-black'} after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
              `} >
            {t(text)}
          </Link>
          {isDropdownOpen && (
            <CardList
              currentPath={currentPath}
              items={dropdownItems}
            />
          )}
        </div>
      ) : (
        <Link
          to={href}
          className={`text-xl relative ${textwhite ? 'after:bg-white' : 'after:bg-black'} after:absolute after:h-0.5 after:w-0 after:bottom-0 after:left-0 hover:after:w-full after:transition-all after:duration-300 cursor-pointer
          ${currentPath === href && 'text-gray-400'}`}
        >
          {t(text)}
        </Link>
      )}
    </li>
  );
};

export default NavItem;
