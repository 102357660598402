import React from 'react'

import {
  MasterCocoLoco,
  MasterfinoRonAnejo,
  MasterfinoRonBlanco,
  MasterfinoRonExtraAnejo,
  MasterfinoRonExtraAnejoVV,
  MasterfinoRonSpices
} from '../assets/liquors/master'

import ContainerSpecsMasterfino from './ui/container-specs-masterfino'

const MasterfinoSpecs = () => {
  return (
    <div>
      <ContainerSpecsMasterfino
        id='MasterBlanco'
        image={MasterfinoRonBlanco}
        colorType='bg-master_blanco_primary'
        textColor='text-master_blanco_secondary'
        textColorDescription='text-black'
        type='Blanco'
        description='products.masterfino.white.description'
        aging='products.masterfino.white.aging'
        alcohol='products.masterfino.white.alcohol'
        background='bg-master_blanco_primary'
        backgroundSVGTop='#ffffff'
        backgroundSVGBottom='#181619'
      />
      <ContainerSpecsMasterfino
        id='extraAnejo'
        image={MasterfinoRonExtraAnejo}
        colorType='bg-master_extraanejo_primary'
        textColor='text-master_extraanejo_secondary'
        type='Extra Añejo'
        description='products.masterfino.extraAnejo.description'
        aging='products.masterfino.extraAnejo.aging'
        alcohol='products.masterfino.extraAnejo.alcohol'
        background='bg-master_extraanejo_primary'
        backgroundSVGTop='#181619'
        backgroundSVGBottom='#002552'

      />
      <ContainerSpecsMasterfino
        id='extraAnejoVV'
        image={MasterfinoRonExtraAnejoVV}
        colorType='bg-master_extraanejoVV_primary'
        textColor='text-master_extraanejoVV_secondary'
        type='Extra Añejo VV'
        description='products.masterfino.extraAnejo.description'
        aging='products.masterfino.extraAnejo.aging'
        alcohol='products.masterfino.extraAnejo.alcohol'
        vv
        background='bg-master_extraanejoVV_primary'
        backgroundSVGTop='#002552'
        backgroundSVGBottom='#c39c5b'
      />
      <ContainerSpecsMasterfino
        id='MasterAnejo'
        image={MasterfinoRonAnejo}
        colorType='bg-master_anejo_primary'
        textColor='text-master_anejo_secondary'
        type='Añejo'
        description='products.masterfino.anejo.description'
        aging='products.masterfino.anejo.aging'
        alcohol='products.masterfino.anejo.alcohol'
        background='bg-master_anejo_primary'
        backgroundSVGTop='#c39c5b'
        backgroundSVGBottom='#972b31'
      />
      <ContainerSpecsMasterfino
        id='Masterspiced'
        image={MasterfinoRonSpices}
        colorType='bg-master_spiced_primary'
        textColor='text-master_spiced_secondary'
        type='Spiced Rum'
        description='products.masterfino.spiced.description'
        aging='products.masterfino.spiced.aging'
        alcohol='products.masterfino.spiced.alcohol'
        background='bg-master_spiced_primary'
        backgroundSVGTop='#972b31'
        backgroundSVGBottom='#8cb22c'
      />

      <ContainerSpecsMasterfino
        id='Mastertropical'
        image={MasterCocoLoco}
        colorType='bg-master_tropical_primary'
        textColor='text-master_tropical_secondary'
        type='Tropical'
        description='products.masterfino.tropical.description'
        aging='products.masterfino.tropical.aging'
        alcohol='products.masterfino.tropical.alcohol'
        isRender
        background='bg-master_tropical_primary'
        backgroundSVGTop='#8cb22c'
        backgroundSVGBottom='#ffffff'
      />
    </div>
  )
}

export default MasterfinoSpecs
