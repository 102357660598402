import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import emailjs from '@emailjs/browser';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'


const ContactForm = () => {
  const [t] = useTranslation('global');
  const form = useRef();


  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    user_company: '',
    user_number: '',
    user_website: '',
    message: '',
  });

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [emailStatus, setEmailStatus] = useState()
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState('bg-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500');

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const requiredFields = ['user_name', 'user_email', 'user_company', 'user_number', 'message'];
    const areAllRequiredFieldsFilled = requiredFields.every((fieldName) => {
      const fieldValue = formData[fieldName];
      return fieldValue !== undefined && fieldValue.trim() !== '';
    });

    setIsSubmitDisabled(!areAllRequiredFieldsFilled);
  };

  const handlePhoneChange = (phoneNumber) => {
    setFormData((prevData) => ({
      ...prevData,
      user_number: phoneNumber,
    }));

    // Check if all required fields are filled, including phone number
    const requiredFields = ['user_name', 'user_email', 'user_company', 'user_number', 'message'];
    const areAllRequiredFieldsFilled = requiredFields.every((fieldName) => {
      const fieldValue = formData[fieldName];
      return fieldValue !== undefined && fieldValue.trim() !== '';
    });

    setIsSubmitDisabled(!areAllRequiredFieldsFilled);
  };


  const sendEmail = async (e) => {
    e.preventDefault();

    if (formData.user_number === undefined) {
      setEmailStatus('Error: Phone number is required');
      setIsEmailSent(false);
      setButtonBackgroundColor('bg-red-500');
      setTimeout(() => {
        setButtonBackgroundColor('bg-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500');
      }, 4000);
      return;
    }

    try {
      const result = await emailjs.sendForm(
        'service_c8vujgr',
        'template_914324t',
        form.current,
        '2N0YEKn7_AsMOJC7H'
      );
      console.log(result.text);
      setButtonBackgroundColor('bg-green-500');
      form.current.reset();
      setEmailStatus('Email sent successfully!');
      setIsEmailSent(true);
    } catch (error) {
      console.error(error.text);
      setButtonBackgroundColor('bg-red-500');
      setEmailStatus('Error sending email. Please try again.');
      setIsEmailSent(false);
    } finally {

      setTimeout(() => {
        setEmailStatus(null);
        setButtonBackgroundColor('bg-blue-500 bg-blue-500 group hover:bg-blue-500 active:bg-blue-500 active:border-blue-500');
      }, 4000);
    }
  };


  return (
    <div className='lg:w-1/3'>
      <h1 className='font-bold text-4xl my-2'>{t('footer.form.title')}</h1>
      <div className='my-2'>
        {t('footer.form.text')}
      </div>
      <form ref={form} onSubmit={sendEmail} className='flex-col'>

        <div className='flex-col'>
          <label>{t('footer.form.name')}<span className='text-red-500'>*</span></label>
          <input
            type='text'
            name='user_name'
            value={formData.user_name}
            onChange={handleInputChange}
            className='w-full rounded px-2 h-8 lg:h-10 border-2 border-black focus:outline-blue-500' />
        </div>

        <div className='flex justify-between'>
          <div className='flex-col w-1/2 mr-2'>
            <label>{t('footer.form.email')}<span className='text-red-500'>*</span></label>
            <input
              type='email'
              name='user_email'
              value={formData.user_email}
              onChange={handleInputChange}
              className='rounded px-2 h-8 lg:h-10 w-full border-2 border-black focus:outline-blue-500' />
          </div>

          <div className='flex-col w-1/2'>
            <label>{t('footer.form.company')}<span className='text-red-500'>*</span></label>
            <input
              type='text'
              name='user_company'
              value={formData.user_company}
              onChange={handleInputChange}

              className='rounded px-2 h-8 lg:h-10 w-full border-2 border-black focus:outline-blue-500' />
          </div>
        </div>

        <div className='flex justify-between'>
          <div className='flex-col w-1/2 mr-2 relative'>
            <label className='flex flex-row gap-2'>
              {t('footer.form.number')}
              <span className='text-red-500'>*</span>
            </label>
            <PhoneInput
              className='rounded px-2 h-8 lg:h-10 w-full border-2 border-black bg-white'
              defaultCountry='MX'
              name='user_number'
              onChange={handlePhoneChange}
              value={formData.user_number}
            />
          </div>

          <div className='flex-col w-1/2'>
            <label>{t('footer.form.website')}</label>
            <input
              type='text'
              name='user_website'
              value={formData.user_website}
              onChange={handleInputChange}
              className='rounded px-2 h-8 lg:h-10 w-full border-2 border-black focus:outline-blue-500' />
          </div>
        </div>

        <div className='flex-col'>
          <label>{t('footer.form.message')}<span className='text-red-500'>*</span></label>
          <textarea
            name='message'
            value={formData.message}
            onChange={handleInputChange}

            className='w-full rounded h-40 p-2 lg:h-20 border-2 border-black focus:outline-blue-500' />
        </div>
        <button
          type='submit'
          disabled={isSubmitDisabled}
          className={`rounded-lg relative w-36 h-10 cursor-pointer flex items-center border
            ${isSubmitDisabled ? 'bg-gray-400' : buttonBackgroundColor}`}
        >
          <span className="text-gray-200 font-semibold ml-8 transform group-hover:translate-x-20 group-hover:opacity-0 transition-all duration-300">{t('footer.form.submit')}</span>
          <span className={`absolute right-0 h-full w-10 rounded-lg ${isSubmitDisabled ? 'bg-gray-400' : 'border-blue-500'} flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300`}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" /></svg>
          </span>
        </button>
        {emailStatus && (
          <span className={`text-${isEmailSent ? 'green' : 'red'}-500`}>
            {emailStatus}
          </span>
        )}
      </form>
    </div>
  )
}

export default ContactForm
