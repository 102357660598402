import VodkaGreenapple from './vodka_greeapple.png'
import VodkaMango from './vodka_mango.png'
import VodkaMix from './vodka_mix.png'
import VodkaNatural from './vodka_natural.png'
import VodkaStrawberry from './vodka_straberry.png'
import VodkaTangerine from './vodka_tangerine.png'
import VodkaGroup from './warner_group.png'
import VodkaWarner from './Warner.png'
import LogoWarner from './logowarner.png'

// import VodkaRainbow from './Warner&Blanc_Vodka_Rainbow_750ml.jpg.jpg'
const VodkaRainbow = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710109149/twmfhuw28txouoibpu2b.webp'
export {
  VodkaGreenapple,
  VodkaMango,
  VodkaMix,
  VodkaNatural,
  VodkaStrawberry,
  VodkaTangerine,
  VodkaGroup,
  VodkaWarner,
  LogoWarner,

  VodkaRainbow
}
