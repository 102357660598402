import MasterAnejo from './masterfino_anejo.png'
import MasterBlanco from './masterfino_blanco.png'
import MasterCocoLoco from './masterfino_cocoloco.png'
import MasterExtraAnejo from './masterfino_extranejo.png'
import MasterSpiced from './masterfino_spiced.png'
import MasterGroup from './master_group.png'
import LogoMasterfino from './masterfino_Logo.png'

// import MasterfinoRonAnejo from './Masterfino_Ron Anejo_750ml.jpg.jpg'
// import MasterfinoRonBlanco from './Masterfino_Ron Blanco_750ml.jpg.jpg'
// import MasterfinoRonExtraAnejo from './Masterfino_Ron ExtraAnejo_750ml.jpg.jpg'
// import MasterfinoRonExtraAnejoVV from './Masterfino_Ron ExtraAnejoVV_750ml.jpg'
// import MasterfinoRonSpices from './Masterfino_Ron SpicesRum_750ml.jpg.jpg'

const MasterfinoRonAnejo = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710108632/lzczlfxm0agrareykhmc.webp'
const MasterfinoRonBlanco = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710108632/bdaievffw4xjlprevnnj.webp'
const MasterfinoRonExtraAnejo = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710108632/ptbckuvg5wg1zu9rwgv7.webp'
const MasterfinoRonExtraAnejoVV = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710108632/kapjzt3lqszdrmqiovdp.webp'
const MasterfinoRonSpices = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710108632/lvbrylvypxekyplune9n.webp'
export {
  MasterAnejo,
  MasterBlanco,
  MasterCocoLoco,
  MasterExtraAnejo,
  MasterSpiced,
  MasterGroup,
  LogoMasterfino,

  MasterfinoRonAnejo,
  MasterfinoRonBlanco,
  MasterfinoRonExtraAnejo,
  MasterfinoRonExtraAnejoVV,
  MasterfinoRonSpices
}
