import React from 'react'
import Layout from '../../../components/layout'
import ComingSoon from '../../../components/ui/in-progress'
import useSEO from '../../../hooks/useSEO'

const WarnerView = () => {
  useSEO({
    description: `Indulge in the exquisite flavors of Warner & Blanc Vodka, meticulously crafted by Embotelladora Global de Licores, Glicorsa SA de CV. Experience the epitome of premium vodka, blending quality and sophistication in every sip.`,
    title: 'Glicorsa | Warner & Blanc Vodka'
  })

  return (
    <Layout classes={`bg-inprogres `}>
      <div
        className='
          min-h-screen
          flex flex-col items-center pt-40
          bg-cover bg-no-repeat bg-center
          bg-hero-warner'
      />
      <ComingSoon />
    </Layout>
  )
}

export default WarnerView
