import React, { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Brazil, Chinese, France, Germany, Japan, Mexico, Usa } from '../assets/translation';


const LanguageContext = createContext();

const languageIcons = {
  es: Mexico,
  en: Usa,
  fr: France,
  pt: Brazil,
  al: Germany,
  zh: Chinese,
  ja: Japan,
};

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation('global');
  const defaultLanguage = 'en';
  const storedLanguage = localStorage.getItem('selectedLanguage') || defaultLanguage;
  const flagIcon = languageIcons[storedLanguage] || null;
  const [flag, setFlag] = useState(flagIcon);
  const [language, setLanguage] = useState(storedLanguage);

  useEffect(() => {
    setFlag(flagIcon);
    i18n.changeLanguage(storedLanguage);
  }, [flagIcon, storedLanguage, i18n]);

  const changeLanguage = async (language, flagImage) => {
    try {
      i18n.changeLanguage(language).then(() => {
        setFlag(flagImage);
        setLanguage(language)
        localStorage.setItem('selectedLanguage', language);
      });
    } catch (error) {
      console.log('Error changing language', error)
    }
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, flag, languageIcons }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};
