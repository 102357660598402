import React from 'react'
import { useTranslation } from 'react-i18next';

const ButtonRedirect = ({ href, text }) => {
  const [t] = useTranslation('global');
  return (
    <a href={href} className=' text-black hover:bg-black hover:text-white border border-black duration-200 p-3 rounded-sm w-fit'>
      {t('more.info')}
    </a>
  )
}

export default ButtonRedirect
