import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { motion, useInView, useAnimation } from "framer-motion";


const CountDown = ({ classes, targetDate }) => {
  const { t } = useTranslation('global');
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })
  const mainControls = useAnimation()

  const calculateTimeLeft = useCallback(() => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24))
      };
    }

    return timeLeft;
  }, [targetDate])

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    if (isInView) {
      mainControls.start({ opacity: 1, x: 0 })
    }

    return () => clearTimeout(timer);
  }, [isInView, mainControls, calculateTimeLeft]);

  const countdown = Object.keys(timeLeft).map(interval => (
    <span key={interval}>
      {timeLeft[interval]} {t(interval)}{' '}
    </span>
  ));

  return (
    <motion.div
      className={classes}
      initial={{ opacity: 0 }}
      ref={ref}
      animate={mainControls}
      transition={{
        default: { duration: 1.5, ease: "easeInOut" },
        fill: { duration: 1.5, ease: [1, 0, 0.8, 1] }
      }}
    >
      <h1>
        {t('comingsoon')}
      </h1>
      <div
        className='flex flex-row gap-4 justify-between items-center'>
        <h2>{countdown.length && countdown}</h2>
      </div>
    </motion.div>
  );
};

export default CountDown;
