import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import useSEO from '../../hooks/useSEO'
import { useTranslation } from 'react-i18next';
import ContactForm from '../../components/ui/contact-form';


const ConctactView = () => {
  const { t } = useTranslation('global');

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  },[])

  useSEO({
    description: `Connect with Embotelladora Global de Licores, Glicorsa SA de CV. Reach out and explore partnership opportunities or get in touch for any inquiries. Your journey to exceptional spirits begins with a simple contact.`,
    title: 'Glicorsa | Contact Us - Explore Partnership Opportunities'
  })

  if(!isMounted) {return null}

  return (
    <Layout disableform={true} classes={`bg-about`}>
      <div className='p-7 py-48 flex-col flex items-center min-h-screen gap-16'>
        <div className="flex flex-col gap-2 lg:w-2/6">
          <div className='font-bold text-4xl'>{t('contact.contact.title')}</div>
          <div className='text-justify'>{t('contact.contact.text')}</div>
        </div>
        <ContactForm />
        <div
          id='info'
          className='w-full h-full flex justify-center items-center flex-col gap-4'>
          <div className='flex flex-col gap-2 text-center'>
            <div>Mo-Fr 10:00-20:00, Sa-Su 10:00-13:00</div>
            <div>Gral. Ramón Corona 557, Real Centenario, 28984 Villa de Álvarez, Col.</div>
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d559.9245478451784!2d-103.75874299358387!3d19.25153790520391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8425456ea8c7944f%3A0x6d4b6d5b4acf5015!2sGLICORSA!5e0!3m2!1sen!2smx!4v1706312126382!5m2!1sen!2smx"
            className='w-5/6 lg:w-1/2 h-96 rounded-xl'
            allowFullScreen=""
            loading="async"
            referrerPolicy="no-referrer-when-downgrade"
            title='Glicorsa'></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default ConctactView
