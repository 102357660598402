import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';

const AcceptCookies = () => {
  const [isCookieAccepted, setIsCookieAccepted] = useState(null);

  useEffect(() => {
    const cookiesWithMilk = Cookies.get('accepetedCookies');
    if (cookiesWithMilk !== undefined) {
      setIsCookieAccepted(cookiesWithMilk === 'true');
    }
  }, []);

  const handleIsCookieAccepted = () => {
    setIsCookieAccepted(true);
    Cookies.set('accepetedCookies', true, { expires: 365 });
  };

  const handleIsCookieDeclined = () => {
    setIsCookieAccepted(false);
    Cookies.set('accepetedCookies', false);
  };

  if (isCookieAccepted !== null) {
    return null;
  }

  return (
    <div className="fixed w-screen min-h-80 bottom-0 bg-vegasbackground p-5 z-50 flex">
      <div className="h-full rounded text-white flex-col lg:flex-row flex items-center gap-2">
        <div className="flex flex-col lg:w-1/2">
          <p className="text-2xl font-bold">
            Privacy Summary
          </p>
          <p className="text-justify">
            This website uses cookies so that we can offer you the best user experience possible. Cookie information is stored in your browser and performs functions such as recognizing you when you return to our website or helping our team understand which sections of the website you find most interesting and useful.
          </p>
        </div>
        <div className="lg:w-1/2 flex-col flex items-center justify-center gap-8">
          <button onClick={handleIsCookieAccepted}
            className="border border-white p-2 bg-white text-black hover:bg-vegasbackground hover:text-white duration-200">
            Accept Cookies
          </button>
          <button onClick={handleIsCookieDeclined}
            className="border border-white p-2 hover:bg-white hover:text-black duration-200">
            Decline Cookies
          </button>
        </div>
      </div>
    </div>
  );
};

export default AcceptCookies;
