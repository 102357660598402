import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from 'react-i18next'


const ExportTable = ({ bottlesxbox, boxesxPallet, palletesxContainer, totalBoxes, totalBottles, image, ml }) => {
  const [t] = useTranslation('global');
  const ExportationTableRef = useRef(null);
  const ExportationTableControls = useAnimation()
  const ExportationTableInView = useInView(ExportationTableRef, { once: true });

  useEffect(() => {
    if(ExportationTableInView) ExportationTableControls.start({opacity: 1, x: 0});
  }, [ExportationTableInView, ExportationTableControls])

  return (
    <div className='flex flex-col gap-8'>
      <div className='flex flex-row gap-4'><img src={image} alt={image} className='h-8 w-auto' />{ml}</div>
      <div className='flex flex-row w-full gap-16'>
        <motion.div>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .1
            }}>{t('services.export.table.bottlesPerBox')}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .2
            }}>{t('services.export.table.boxesPerPallete')}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .3

            }}>{t('services.export.table.palletesPerContainer')}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .4

            }}>{t('services.export.table.totalBoxes')}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .5

            }}>{t('services.export.table.totalBottles')}</motion.div>
        </motion.div>
        <motion.div>
          <motion.div
            initial={{ opacity: 0, x: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .1

            }}>{bottlesxbox}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .2

            }}>{boxesxPallet}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .3

            }}>{palletesxContainer}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .4
            }}>{totalBoxes}</motion.div>
          <motion.div
            initial={{ opacity: 0, x: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .5

            }}>{totalBottles}</motion.div>
        </motion.div>
      </div>
    </div>
  )
}

export default ExportTable
