import VelocityLogoHorizontal from './velocity.png'
import VelocityLogoHorizontalWhite from './Capa 1.svg'
import VelocityLogoVerticalWhite from './velocityverticalwhite.svg'
import LogoVelocity from './logovelocity.png'

// import VelocityTequilaBlancoBig from './Velocity_Tequila Blanco_1l.webp'
// import VelocityTequilaBlanco from './Velocity_Tequila Blanco 100Agave_750ml.webp'
// import VelocityTequilaReposado from './Velocity_Tequila Reposado 100Agave_750ml.webp'
// import VelocityTequilaAnejo from './Velocity_Tequila Anejo 100Agave_750ml.webp'
// import VelocityTequilaExtraAnejo from './Velocity_Tequila ExtraAnejo 100Agave_750ml.webp'
// import VelocityTequilaExtraAnejoVV from './Velocity_Tequila Extra Anejo VV 100Agave_750ml.webp'
// import VelocityTequilaAnejoCristalino from './Velocity_Tequila Anejo Cristalino 100Agave_750ml.webp'

const VelocityTequilaBlancoBig = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107875/lq0cpfddq2pieaavhztm.webp'
const VelocityTequilaBlanco = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107446/nbtq03ageewpcznfuyox.webp'
const VelocityTequilaReposado = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107874/efwq3hfmqiwn9wgvpdke.webp'
const VelocityTequilaAnejo = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107874/vhsmailc5f69origlc39.webp'
const VelocityTequilaExtraAnejo = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107874/soq38lgoypztvauvd6re.webp'
const VelocityTequilaExtraAnejoVV = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107874/y8g8w1zlz3f73e1cfzee.webp'
const VelocityTequilaAnejoCristalino = 'https://res.cloudinary.com/dqw95j7y9/image/upload/v1710107874/bqviclww8kkzf3orextj.webp'


export {
  VelocityLogoHorizontal,
  VelocityLogoHorizontalWhite,
  LogoVelocity,
  VelocityLogoVerticalWhite,

  VelocityTequilaBlancoBig,
  VelocityTequilaBlanco,
  VelocityTequilaReposado,
  VelocityTequilaAnejo,
  VelocityTequilaExtraAnejo,
  VelocityTequilaExtraAnejoVV,
  VelocityTequilaAnejoCristalino
}
