import { Link } from "react-router-dom";

const CardNav = ({ href, text, image, type }) => {
  return (
    <div className="p-2 flex flex-col items-center justify-center gap-3 text-white max-w-[400px] mx-auto">
      <div className="aspect-square h-[500px]">
        <img alt="Liquor" src={image} className="aspect-square object-scale-down"/>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <p className="font-bold text-2xl">{text}</p>
        <p>|</p>
        <p className="font-extralight text-xl">{type}</p>
      </div>
      <Link
        to={href}
        className="hover:bg-slate-200 duration-200 px-16 py-1 border-2 hover:text-black">
        {'see more'}
      </Link>
    </div>
  );

}

export default CardNav;
