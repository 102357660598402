import React from 'react';
import Layout from '../../components/layout';
import CoverflowSwiper from '../../components/ui/slider';
import {
  MasterfinoRonAnejo,
  MasterfinoRonBlanco,
  MasterfinoRonExtraAnejo,
  MasterfinoRonExtraAnejoVV,
  MasterfinoRonSpices,
} from '../../assets/liquors/master';

import {
  VodkaGreenapple,
  VodkaMango,
  VodkaMix,
  VodkaNatural,
  VodkaRainbow,
  VodkaStrawberry,
  VodkaTangerine,
} from '../../assets/liquors/warner';
import useSEO from '../../hooks/useSEO';
import { VelocityTequilaAnejo, VelocityTequilaAnejoCristalino, VelocityTequilaBlanco, VelocityTequilaExtraAnejo, VelocityTequilaReposado } from '../../assets/liquors/velocity';

const Masterslides = [
  { image: MasterfinoRonAnejo, alt: 'masterfino' },
  { image: MasterfinoRonBlanco, alt: 'masterfino' },
  { image: MasterfinoRonExtraAnejo, alt: 'masterfino' },
  { image: MasterfinoRonExtraAnejoVV, alt: 'masterfino' },
  { image: MasterfinoRonSpices, alt: 'masterfino' },
];

const VelocitySlides = [
  { image: VelocityTequilaBlanco, alt: 'vel' },
  { image: VelocityTequilaAnejo, alt: 'vel' },
  { image: VelocityTequilaAnejoCristalino, alt: 'vel' },
  { image: VelocityTequilaExtraAnejo, alt: 'vel' },
  { image: VelocityTequilaReposado, alt: 'vel' },
];

const VodkaSlides = [
  { image: VodkaRainbow, alt: 'vodka' },
  { image: VodkaMix, alt: 'vodka' },
  { image: VodkaNatural, alt: 'vodka' },
  { image: VodkaGreenapple, alt: 'vodka' },
  { image: VodkaMango, alt: 'vodka' },
  { image: VodkaStrawberry, alt: 'vodka' },
  { image: VodkaTangerine, alt: 'vodka' },
];

const ProductsView = () => {
  useSEO({
    description: `Explore a diverse range of exceptional spirits including Tequila, Ron, and Vodka from Embotelladora Global de Licores, Glicorsa SA de CV. Immerse yourself in the world of premium beverages crafted with passion and expertise.`,
    title: 'Glicorsa | Exquisite Spirits Collection'
  })

  return (
    <Layout
      disableform={true}
      classes='bg-inprogres'
      textwhite
      footerBackground='bg-vegasbackground'
      footerTextWhite
    >
      <div className={` min-h-screen flex flex-col items-center pt-40 bg-cover bg-no-repeat bg-center bg-hero-vegasFilter`}>
        <div className='flex flex-col gap-8 xl:flex-row min-h-screen items-center justify-center'>
          <CoverflowSwiper slides={VelocitySlides} brand='Velocity' type='Tequila' href='/products/velocity'/>
          <CoverflowSwiper slides={Masterslides} brand='Masterfino' type='Ron' href='/products/masterfino'/>
          <CoverflowSwiper slides={VodkaSlides} brand='Warner & Blanc' type='Vodka' href='/products/warner'/>
        </div>
      </div>
    </Layout>
  );
};

export default ProductsView;
