import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import {
  Autoplay,
  EffectCoverflow
} from 'swiper/modules';
import { Link } from 'react-router-dom';


const CoverflowSwiper = ({
  slides,
  brand,
  type,
  href
}) => {

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) { return null }

  return (
    <div className='flex flex-col gap-8'>
      <Swiper
        effect="coverflow"
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        grabCursor={true}
        rewind={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="w-full h-auto flex items-center justify-center">
              <img src={slide.image} alt={slide.alt} className="w-auto xl:h-[600px] lg:h-[800px] md:h-[700px] h-[400px]" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex flex-row gap-2 items-center justify-center text-white">
        <p className="font-bold text-2xl">{brand}</p>
        <p>|</p>
        <p className="font-extralight text-xl">{type}</p>
      </div>
      <div className='flex items-center justify-center'>
        <Link
          to={href}
          className='border-2 border-white text-white px-16 py-1 hover:bg-slate-300 hover:text-black duration-200'
        >
          See more
        </Link>
      </div>
    </div>
  );
};

export default CoverflowSwiper;
