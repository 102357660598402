import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from 'react-i18next';
import { EffectCards, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';

import useSEO from '../../hooks/useSEO';

import { VelocityLogoHorizontal } from '../../assets/liquors/velocity';
import {
  Capacity1,
  Capacity2,
  Capacity3,
  Services,
  Services1,
  Services2,
  Services3,
  Services4
} from '../../assets/services'
import {
  LicorSabor,
  Mezcal,
  Ron,
  Rtd,
  Tequila,
  Vodka,
  Whisky
} from '../../assets/mockups';

import Layout from '../../components/layout'
import ImageCollage from '../../components/ui/image-collage';
import ExportTable from '../../components/ui/export-table';


const ServicesView = () => {
  const { t } = useTranslation('global');
  useSEO({
    description: `Discover exceptional liquor-making services at Glicorsa. Transform your vision into reality with our comprehensive services, handling everything from exportation to crafting and bottling. Trust Embotelladora Global de Licores, Glicorsa SA de CV, to bring your unique blends to life.`,
    title: 'Glicorsa | Premium Liquor Crafting and Bottling Services'
  })

  const ServicesRef = useRef(null);
  const ExportationRef = useRef(null);
  const ExportationTableRef = useRef(null);

  const ServicesControls = useAnimation()
  const ExportationControls = useAnimation()
  const ExportationTableControls = useAnimation()

  const ServicesInView = useInView(ServicesRef, { once: true });
  const ExportationInView = useInView(ExportationRef, { once: true });
  const ExportationTableInView = useInView(ExportationTableRef, { once: true });

  useEffect(() => {
    if (ServicesInView) ServicesControls.start({ opacity: 1, y: 0 });
    if (ExportationInView) ExportationControls.start({ opacity: 1, scale: 1 });
    if (ExportationTableInView) ExportationTableControls.start({ opacity: 1, y: 0 });
  }, [
    ServicesInView,
    ServicesControls,
    ExportationInView,
    ExportationControls,
    ExportationTableControls,
    ExportationTableInView
  ])


  return (
    <Layout classes={`bg-about `} textwhite>
      <motion.div
        id='services-hero' className='p-7 bg-hero-services bg-cover bg-no-repeat bg-center h-screen items-center justify-center py-40 lg:flex'>
        <motion.div className='lg:w-1/2 h-full flex flex-col gap-4 justify-center items-center'>
          <h2 className='text-center text-white text-3xl lg:text-8xl md:text-5xl font-semibold mb-2 font-cursive'>{t('services.intro.title')}</h2>
          <p className='text-gray-200 text-lg lg:text-4xl md:text-3xl text-center'>
            {t('services.intro.text')}
          </p>
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        animate={ServicesControls}
        ref={ServicesRef}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .5
        }}
        id='services' className='p-7 items-center justify-center flex-col flex lg:flex-row min-h-screen gap-8'>

        <ImageCollage upright={Services1} upleft={Services2} downright={Services3} downleft={Services4} />

        <motion.div className='lg:w-1/2 w-5/6 flex flex-col gap-8'>
          <div className='flex flex-col gap-2'>
            <p className='text-gray-600 text-xl lg:text-2xl font-semibold'>
              {t('services.services.text')}
            </p>
          </div>
          <div className='flex flex-col gap-2'>
            <h2 className='text-3xl font-semibold text-gray-800'>{t('services.designs.title')}</h2>
            <p className='text-gray-600 text-xl '>
              {t('services.designs.text')}
            </p>
          </div>
          <div className='flex flex-col gap-2'>
            <h2 className='text-3xl font-semibold text-gray-800'>{t('services.packaging.title')}</h2>
            <p className='text-gray-600 text-xl '>
              {t('services.packaging.text')}
            </p>
          </div>
        </motion.div>
      </motion.div>


      <motion.div className='min-h-[50vh] flex flex-col gap-16 pb-16 items-center justify-center text-4xl font-bold'>
        <p className='text-3xl font-bold text-center'>{t('services.products.title')}</p>
        <Swiper
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards, Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className='mySwiper lg:w-72 w-64 h-96 flex justify-center items-center'
        >
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8 '>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Tequila} alt='Tequila' className='h-4/5' />
              <p>{t('services.products.tequila')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Mezcal} alt='Mezcal' className='h-4/5' />
              <p>{t('services.products.mezcal')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Ron} alt='Ron' className='h-4/5' />
              <p>{t('services.products.ron')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Vodka} alt='Vodka' className='h-4/5' />
              <p>{t('services.products.vodka')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Whisky} alt='Whisky' className='h-4/5' />
              <p>{t('services.products.whisky')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={LicorSabor} alt='LicorSabor' className='h-4/5' />
              <p>{t('services.products.liquor')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Vodka} alt='Cream' className='h-4/5' />
              <p>{t('services.products.cream')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className='rounded-xl bg-white w-72 h-40 p-8'>
            <div className='font-extrabold text-2xl drop-shadow-md h-full flex flex-col justify-between items-center'>
              <img src={Rtd} alt='Ready to Drink' className='h-4/5' />
              <p>{t('services.products.rtd')}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </motion.div>

      <motion.div id='export' className='p-7 items-center flex flex-col min-h-screen gap-16'>
        <motion.div id='export-info' className='lg:text-start w-5/6 h-full flex flex-col gap-8'>
          <h2 className='text-3xl h-full font-semibold text-gray-800'>{t('services.export.title')}</h2>
          <motion.div className='flex items-center justify-center'>
            <div className='flex flex-row gap-2 items-center justify-center border-2 border-black rounded-md p-2 border-dashed'>
              <div className='flex flex-col justify-between h-full text-end'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={ExportationControls}
                  ref={ExportationRef}
                  transition={{
                    duration: 0.5,
                    ease: [0, 0.71, 0.2, 1.01],
                    delay: .15,
                    scale: {
                      type: "linear",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                  className='  h-28 p-2 flex flex-col justify-center  '><p className='text-4xl font-bold'>55,000</p> <p>{t('services.export.day')}</p></motion.div>
                <div className='w-full h-0.5 bg-slate-600'></div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={ExportationControls}
                  ref={ExportationRef}
                  transition={{
                    duration: 1,
                    delay: .15,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "linear",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                  className='  h-28 p-2 flex flex-col justify-center  '><p className='text-4xl font-bold'>6,000,000</p> <p>{t('services.export.storage')}</p></motion.div>
              </div>
              <div className='w-0.5 h-60 bg-slate-600'></div>
              <div className='flex-col flex justify-between'>
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={ExportationControls}
                  ref={ExportationRef}
                  transition={{
                    delay: .15,
                    duration: 1.5,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "linear",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                  className='  h-28 p-2 flex flex-col justify-center  '><p className='text-4xl font-bold'>1,000,000</p> <p>{t('services.export.aged')}</p></motion.div>
                <div className='w-full h-0.5 bg-slate-600'></div>

                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={ExportationControls}
                  ref={ExportationRef}
                  transition={{
                    delay: .15,
                    duration: 2,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "linear",
                      damping: 5,
                      stiffness: 100,
                      restDelta: 0.001
                    }
                  }}
                  className='  h-28 p-2 flex flex-col justify-center  '><p className='text-4xl font-bold'>5,000</p> <p>{t('services.export.boxes')}</p></motion.div>
              </div>
            </div>
          </motion.div>
          <motion.p
            initial={{ opacity: 0, y: '20%' }}
            animate={ExportationTableControls}
            ref={ExportationTableRef}
            transition={{
              duration: 1,
              ease: 'easeInOut',
              delay: .5
            }}
            className='text-gray-600 lg:text-xl text-lg'>
            {t('services.export.text')}
          </motion.p>
        </motion.div>
        <motion.div
          className='w-full flex flex-col items-center lg:text-xl gap-8'>
          <div className='flex flex-row items-end gap-4'><img src={VelocityLogoHorizontal} alt='velocitylogo' className='lg:h-16 md:h-16 h-10 w-auto' />{t('services.export.title')}</div>
          <div className='lg:w-4/6 flex lg:flex-row flex-col justify-evenly items-center gap-8'>
            <ExportTable
              bottlesxbox={'6'}
              boxesxPallet={'80'}
              palletesxContainer={'26'}
              totalBoxes={'2,080'}
              totalBottles={'12,480'}
              ml={'750ml'}
              image={VelocityLogoHorizontal}
            />
            <ExportTable
              bottlesxbox={'6'}
              boxesxPallet={'66'}
              palletesxContainer={'26'}
              totalBoxes={'1,716'}
              totalBottles={'10,296'}
              ml={'1L'}
              image={VelocityLogoHorizontal}
            />
          </div>
        </motion.div>
        <ImageCollage upright={Capacity1} upleft={Capacity2} downright={Capacity3} downleft={Services} />
      </motion.div>

    </Layout>
  )
}

export default ServicesView
