import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'

import { HomeImage2, HomeImage3, HomeImage5 } from '../../assets/company';
import {
  CampoGif,
  CascoBruce,
  Expertise1,
  Expertise2,
  Fine1,
  Fine2,
  Fine4,
  Planning,
  Selection4,
  SelectionMachine,
  SuitBruce
} from '../../assets/about';
import { GlicorsaOnlyText } from '../../assets/logo';
import {
  Agave,
  Aging,
  Cooked,
  Distillation,
  Extraction,
  Fermentation,
  Filtration,
  Packaging,
  Pina
} from '../../assets/services';
import { VelocityLogoHorizontal } from '../../assets/liquors/velocity';

import Layout from '../../components/layout';
import ImageCollage from '../../components/ui/image-collage';

import useSEO from '../../hooks/useSEO';

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};

const AboutUsView = () => {
  const { t } = useTranslation('global');

  useSEO({
    description: `Gain in-depth insights into the legacy and expertise of Embotelladora Global de Licores, Glicorsa SA de CV. Explore our rich history and commitment to delivering exceptional quality and innovation in the world of spirits.`,
    title: 'Glicorsa | About Us'
  })

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const initialX = isMobile ? '-20%' : '20%';

  const theManRef = useRef(null);
  const theBeginingRef = useRef(null);
  const thePlaceRef = useRef(null);
  const theExpertiseRef = useRef(null);
  const theSelectionRef = useRef(null);
  const theJimadorRef = useRef(null);
  const misionVisionRef = useRef(null);
  const processRef = useRef(null);

  const theManControls = useAnimation()
  const theBeginingControls = useAnimation()
  const thePlaceControls = useAnimation()
  const theExpertiseControls = useAnimation()
  const theSelectioControls = useAnimation()
  const theJimadorControls = useAnimation()
  const misionVisionControls = useAnimation()
  const processControls = useAnimation()

  const theManInView = useInView(theManRef, { once: true });
  const theBeginingInView = useInView(theBeginingRef, { once: true });
  const thePlaceInView = useInView(thePlaceRef, { once: true });
  const theExpertiseInView = useInView(theExpertiseRef, { once: true });
  const theSelectionInView = useInView(theSelectionRef, { once: true });
  const theJimadorInView = useInView(theJimadorRef, { once: true });
  const misionVisionInView = useInView(misionVisionRef, { once: true });
  const processInView = useInView(processRef, { once: true });

  useEffect(() => {
    if (theManInView) {
      theManControls.start({ opacity: 1, y: 0 });
    }
    if (theBeginingInView) {
      theBeginingControls.start({ opacity: 1, y: 0 });
    }
    if (thePlaceInView) {
      thePlaceControls.start({ opacity: 1, y: 0 });
    }
    if (theExpertiseInView) {
      theExpertiseControls.start({ opacity: 1, y: 0 });
    }
    if (theSelectionInView) {
      theSelectioControls.start({ opacity: 1, y: 0 });
    }
    if (theJimadorInView) {
      theJimadorControls.start({ opacity: 1, y: 0 });
    }
    if (misionVisionInView) {
      misionVisionControls.start({ opacity: 1, y: 0 });
    }
    if (processInView) {
      processControls.start({ opacity: 1, y: 0 });
    }
  }, [
    theBeginingInView,
    theManInView,
    thePlaceInView,
    theExpertiseInView,
    theJimadorInView,
    misionVisionInView,
    processInView,
    theSelectionInView,
    theBeginingControls,
    theManControls,
    thePlaceControls,
    theExpertiseControls,
    theSelectioControls,
    theJimadorControls,
    misionVisionControls,
    processControls
  ]);


  return (
    <Layout classes={`bg-about flex flex-col gap-16`} textwhite={true}>
      <div className='h-screen bg-hero-agaveField  items-center justify-center bg-cover bg-no-repeat bg-center  2xl:bg-fixed'>
        <motion.img
          variants={icon}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 2, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] }
          }}
          src={GlicorsaOnlyText} alt='velocity' className='h-5/6 w-auto'
        />
      </div>


      {/* El hombre */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={theManRef}
        animate={theManControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='theman' className='p-7 items-center flex flex-col lg:flex-row min-h-screen gap-8'>
        <div className='lg:text-start lg:w-1/2 flex flex-col gap-8'>
          <div className='font-semibold mb-2 font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.man.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.man.title')}</div>
          </div>
          <p className='text-gray-600 text-xl'>{t('about.man.text')}</p>
          <p className='text-gray-600 text-xl'>{t('about.target.text')}</p>
        </div>
        <div className='lg:w-1/2 flex justify-center items-center lg:h-screen'>
          <img src={SuitBruce} alt='CEO' className='rounded-xl shadow-md h-auto w-4/6' />
        </div>
      </motion.div>

      {/* El inicio */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={theBeginingRef}
        animate={theBeginingControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='thebegining' className='p-7 items-center flex flex-col lg:flex-row min-h-screen gap-16'>
        <div className='lg:text-end lg:w-1/2'>
          <div className='font-semibold mb-2 font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.begining.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.begining.title')}</div>
          </div>
          <p className='text-gray-600 py-8 text-xl'>
            {t('about.begining.text')}
          </p>
        </div>
        <ImageCollage upright={Fine1} upleft={Fine2} downright={Planning} downleft={Fine4} />
      </motion.div>

      {/* El lugar */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={thePlaceRef}
        animate={thePlaceControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='theplace' className='p-7 items-center flex flex-col lg:flex-row min-h-screen gap-16 '>
        <div className='lg:text-start lg:w-1/2'>
          <div className='font-semibold font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.place.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.place.title')}</div>
          </div>
          <div className='text-gray-600 py-8 text-xl gap-4 flex flex-col'>
            <div>{t('about.place.text1')}</div>
            <div>{t('about.place.text2')}</div>
          </div>
        </div>

        <div className='flex justify-center items-center lg:w-1/2 h-full'>
          <div className='aspect-square bg-black lg:w-10/12 xl:w-7/12 w-12/12 overflow-hidden rounded-md'>
            <img
              src={CampoGif}
              alt='Campos de agave'
              className='object-cover w-full h-full'
            />
          </div>
        </div>

      </motion.div>

      {/* Expertis */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={theExpertiseRef}
        animate={theExpertiseControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='expertise' className='p-7 items-center min-h-screen gap-16 flex flex-col-reverse lg:flex-row pb-16'>
        <ImageCollage upright={Expertise1} upleft={Expertise2} downright={HomeImage5} downleft={HomeImage3} />
        <div className='lg:text-start lg:w-1/2 '>
          <div className='font-semibold pb-2 font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.expertise.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.expertise.title')}</div>
          </div>
          <p className='text-gray-600 text-xl'>
            {t('about.expertise.text')}
          </p>
        </div>
      </motion.div>


      {/* La seleccion */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={theSelectionRef}
        animate={theSelectioControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='selection' className='p-7 items-center flex flex-col lg:flex-row min-h-screen gap-16'>
        <div className='lg:w-1/2 flex flex-col gap-4'>
          <div className='font-semibold font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.selection.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.selection.title')}</div>
          </div>
          <p className='text-gray-600 text-xl'>
            {t('about.selection.text')}
          </p>
          <p className='text-gray-600 text-xl'>
            {t('about.selection.text2')}
          </p>
        </div>
        <ImageCollage upright={CascoBruce} upleft={SelectionMachine} downright={HomeImage2} downleft={Selection4} />
      </motion.div>


      {/* El jimador */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={theJimadorRef}
        animate={theJimadorControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='jimador' className='p-7 items-center flex flex-col lg:flex-row min-h-screen gap-8'>
        <div className='lg:text-start lg:w-1/2 pb-16'>
          <div className='font-semibold mb-2 font-cursive flex flex-row gap-2'>
            <div className='lg:text-3xl text-xl'>{t('about.jimador.title-first')}</div>
            <div className='lg:text-8xl text-5xl'>{t('about.jimador.title')}</div>
          </div>
          <div className='flex flex-col gap-4'>
            <p className='text-gray-600 lg:text-xl'>{t('about.jimador.text1')}</p>
            <p className='text-gray-600 lg:text-xl'>{t('about.jimador.text2')}</p>
          </div>
        </div>
        <div className='h-full  overflow-hidden'>
          <img src={HomeImage2} alt='Jimador de glicorsa' className=' h-full w-full object-cover cursor-pointer transform hover:scale-105 transition-transform duration-300 rounded-md' />
        </div>
      </motion.div>


      {/* Mision y vision */}
      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={misionVisionRef}
        animate={misionVisionControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='mision and vision' className='p-7 lg:p-32 flex lg:flex-row flex-col min-h-[50vh] justify-center items-center gap-8'>
        <div className='lg:w-1/2 flex flex-col gap-4  h-96 justify-center'>
          <div className='font-bold text-4xl'>{t('about.missionvision.vision-title')}</div>
          <div className='text-xl'>{t('about.missionvision.vision')}</div>
        </div>
        <div className='lg:w-1/2 flex flex-col gap-4 h-96 justify-center'>
          <div className='font-bold text-4xl'>{t('about.missionvision.mission-title')}</div>
          <div className='text-xl'>{t('about.missionvision.mission')}</div>
        </div>
      </motion.div>

      {/* Proceso del tequila */}
      <motion.div className='flex justify-center flex-col items-center xl:px-64 lg:px-16 px-10 lg:text-xl'>
        <motion.div className='font-bold lg:text-7xl text-4xl font-cursive flex justify-center items-center'>
          <img src={VelocityLogoHorizontal} alt='velocity logo' className='lg:w-1/2 w-72 pb-16' />
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center  mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}
            className='w-full'>
            <motion.div className='font-semibold text-2xl'>{t('about.process.agave.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.agave.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}
            className=''>
            <div className='aspect-square w-44 flex items-center justify-center '>
              <img src={Agave} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center  mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.pina.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.pina.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Pina} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.cooked.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.cooked.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Cooked} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.extraction.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.extraction.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Extraction} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.fermentation.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.fermentation.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Fermentation} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.distillation.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.distillation.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Distillation} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.filtration.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.filtration.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Filtration} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.aging.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.aging.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Aging} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

        <motion.div className='flex lg:flex-row flex-col w-full justify-between items-center   mb-16'>
          <motion.div
            initial={{ opacity: 0, x: '-20%' }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <motion.div className='font-semibold text-2xl'>{t('about.process.packaging.title')}</motion.div>
            <motion.div className='lg:w-5/6'>{t('about.process.packaging.text')}</motion.div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: initialX }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{
              duration: 1,
              ease: 'easeInOut',
            }}>
            <div className='aspect-square w-44 flex items-center justify-center'>
              <img src={Packaging} alt='agave' />
            </div>
          </motion.div>
        </motion.div>

      </motion.div>
    </Layout>
  );
}

export default AboutUsView;
