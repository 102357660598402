import React from 'react'

import ContainerSpecsVelocity from './ui/container-specs-velocity'

import {
  VelocityTequilaAnejo,
  VelocityTequilaAnejoCristalino,
  VelocityTequilaBlanco,
  VelocityTequilaExtraAnejo,
  VelocityTequilaExtraAnejoVV,
  VelocityTequilaReposado
} from '../assets/liquors/velocity'

const VelocitySpecs = () => {
  return (
    <>
      <ContainerSpecsVelocity
        aging='products.velocity.blanco.aging'
        background='bg-velocityBlancoBg'
        backgroundSVGBottom='#c28ba8'
        backgroundSVGTop='#8eabbf'
        colorType='bg-velocityBlancoType'
        finish='products.velocity.blanco.finish'
        id='VelocityLiquorBlanco'
        image={VelocityTequilaBlanco}
        nose='products.velocity.blanco.nose'
        region='products.velocity.blanco.region'
        taste='products.velocity.blanco.taste'
        textColorDescription='text-black'
        textColorKey='text-black bg-velocityBlancoType p-1 rounded-sm'
        type='Blanco'
        visual='products.velocity.blanco.visual'
      />
      <ContainerSpecsVelocity
        aging='products.velocity.reposado.aging'
        background='bg-velocityReposadoBg'
        backgroundSVGBottom='#bbceca'
        backgroundSVGTop='#c28ba8'
        colorType='bg-velocityReposadoType'
        finish='products.velocity.reposado.finish'
        id='VelocityLiquorReposado'
        image={VelocityTequilaReposado}
        nose='products.velocity.reposado.nose'
        region='products.velocity.reposado.region'
        taste='products.velocity.reposado.taste'
        textColorDescription='text-black'
        textColorKey='text-black bg-velocityReposadoType p-1 rounded-sm'
        type='Reposado'
        variantWave={2}
        visual='products.velocity.reposado.visual'
      />
      <ContainerSpecsVelocity
        aging='products.velocity.anejo.aging'
        background='bg-velocityAnejoBg'
        backgroundSVGBottom='#d1cda0'
        backgroundSVGTop='#bbceca'
        colorType='bg-velocityAnejoType'
        finish='products.velocity.anejo.finish'
        id='VelocityLiquorAnejo'
        image={VelocityTequilaAnejo}
        nose='products.velocity.anejo.nose'
        region='products.velocity.anejo.region'
        taste='products.velocity.anejo.taste'
        textColorDescription='text-black'
        textColorKey='text-black bg-velocityAnejoType p-1 rounded-sm'
        type='Añejo'
        variantWave={2}
        visual='products.velocity.anejo.visual'
      />
      <ContainerSpecsVelocity
        aging='products.velocity.extraAnejo.aging'
        background='bg-velocityExtraAnejoBg'
        backgroundSVGBottom='#d8d4e7'
        backgroundSVGTop='#d1cda0'
        colorType='bg-velocityExtraAnejoType'
        finish='products.velocity.extraAnejo.finish'
        id='VelocityLiquorExtraAnejo'
        image={VelocityTequilaExtraAnejo}
        nose='products.velocity.extraAnejo.nose'
        region='products.velocity.extraAnejo.region'
        taste='products.velocity.extraAnejo.taste'
        textColorDescription='text-black'
        textColorKey='text-black bg-velocityExtraAnejoType p-1 rounded-sm'
        type='Extra Añejo'
        visual='products.velocity.extraAnejo.visual'
      />
      <ContainerSpecsVelocity
        aging='products.velocity.cristalino.aging'
        background='bg-velocityCristalinoBg'
        backgroundSVGBottom='#040404'
        backgroundSVGTop='#d8d4e7'
        colorType='bg-slate-200'
        finish='products.velocity.cristalino.finish'
        id='VelocityLiquorCristalino'
        image={VelocityTequilaAnejoCristalino}
        region='products.velocity.cristalino.region'
        taste='products.velocity.cristalino.taste'
        textColorDescription='text-black'
        textColorKey='text-black bg-slate-200 p-1 rounded-sm'
        type='Añejo Cristalino'
        variantWave={2}
        visual='products.velocity.cristalino.visual'
      />
      <ContainerSpecsVelocity
        aging='products.velocity.exAnejoVV.aging'
        background='bg-velocityExtraAnejoVVBg'
        backgroundSVGBottom='#000017'
        backgroundSVGTop='#040404'
        colorType='bg-white'
        finish='products.velocity.exAnejoVV.finish'
        id='VelocityLiquorAnejoVV'
        image={VelocityTequilaExtraAnejoVV}
        nose='products.velocity.exAnejoVV.nose'
        region='products.velocity.exAnejoVV.region'
        taste='products.velocity.exAnejoVV.taste'
        textColorDescription='text-white'
        textColorKey='text-black bg-white p-1 rounded-sm'
        type='Extra Añejo VV'
        variantWave={2}
        visual='products.velocity.exAnejoVV.visual'
      />
    </>
  )
}

export default VelocitySpecs
