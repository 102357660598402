import React, { useState, useEffect } from "react";
import Cookies from 'js-cookie';
import { Dialog } from "@headlessui/react";
import { GlicorsaComplete } from "../../assets/logo";

const AgeConfirm = () => {
  const [isOverAge, setIsOverAge] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const isLegalAgeCookie = Cookies.get('LegalAge');

    if (isLegalAgeCookie !== undefined) {
      setIsOverAge(isLegalAgeCookie === 'true');
      setIsOpen(false); // Ocultar el diálogo si la cookie existe
    }
  }, []);

  const handleIsOverAge = () => {
    setIsOverAge(true);
    Cookies.set('LegalAge', true, { expires: 365 }); // Caducidad de 365 días para la cookie
    setIsOpen(false); // Ocultar el diálogo después de confirmar la edad
  };

  if (isOverAge !== null) {
    return null;
  }

  const NotLegalRoute = 'https://www.google.com/search?q=orange+juice&sca_esv=8031294fadf62b25&sca_upv=1&udm=2&biw=1872&bih=970&sxsrf=ACQVn09QWkiV7LxmnjseGmyat8M9JtP8YQ%3A1710125478477&ei=pnHuZeHmHKagur8PlqaT-As&ved=0ahUKEwjh0antmeuEAxUmkO4BHRbTBL8Q4dUDCBA&uact=5&oq=orange+juice&gs_lp=Egxnd3Mtd2l6LXNlcnAiDG9yYW5nZSBqdWljZTIKEAAYgAQYigUYQzIKEAAYgAQYigUYQzIKEAAYgAQYigUYQzIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgARIsBRQAFjVEnABeACQAQCYAbEBoAHKCqoBAzkuNLgBA8gBAPgBAZgCDqAC7grCAgQQIxgnmAMAkgcDNy43oAfGQg&sclient=gws-wiz-serp';

  return (
    <Dialog
      open={isOpen}
      onClose={() => { }}
      className='fixed top-0 left-0 z-50 backdrop-blur-sm w-screen h-screen flex justify-center items-center text-center'>
      <Dialog.Panel className='flex flex-col gap-4 bg-inprogres w-96 h-4/6 rounded items-center p-6'>
        <Dialog.Title className='flex justify-center items-center w-full'>
          <img src={GlicorsaComplete} alt="Glicorsa Logo" className="w-4/6" />
        </Dialog.Title>
        <Dialog.Description className='font-bold text-xl'>
          You must confirm if you are over 18
        </Dialog.Description>
        <div className="flex justify-between flex-col h-full">
          <div className="flex flex-col items-center gap-8 h-full justify-evenly">
            <button onClick={handleIsOverAge}
              className="border border-black p-2 w-fit hover:border-white hover:bg-black hover:text-white duration-200"
            >Yes, I'm Legal Age</button>
            <a
              className="border border-black p-2 w-fit hover:border-white hover:bg-black hover:text-white duration-200"
              href={NotLegalRoute}>
              No, I'm not
            </a>
          </div>
          <div className="text-sm">
            <p>By accessing this website you acknowledge that you accept its terms and conditions of use.</p>
            <a href="/legal/privacy-and-cookies-notice" className="underline">See privacy policy</a>
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default AgeConfirm;
