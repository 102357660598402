import React, { useState, useEffect } from 'react';
import { ArrowUpFromLine } from 'lucide-react';
import Navbar from './navbar/index';
import Footer from './footer';
import { Link } from 'react-scroll';

const Layout = ({ children, classes, disableform, textwhite, footerTextWhite, footerBackground }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 200;
      if (scrollPosition > scrollThreshold) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={classes + ' min-h-screen min-w-screen'}>
      <Navbar textwhite={textwhite} id={'navbar'} />
      <div className='min-h-screen'>{children}</div>
      <Link
        to='navbar'
        duration={500}
        offset={20}
        smooth
        spy
        className={`fixed shadow-lg lg:flex bottom-5 right-5 text-black lg:text-4xl text-2xl font-bold bg-velocityprimary opacity-50 p-3 rounded-sm aspect-square hover:opacity-80 duration-400 ${
          showScrollButton ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-full'
        } transition-opacity`}>
        <ArrowUpFromLine size={25} />
      </Link>
      <Footer form={disableform} footerTextWhite={footerTextWhite} footerBackground={footerBackground} />
    </div>
  );
};

export default Layout;
