import React from 'react'
import { InProgres } from '../../assets/random'
import { useTranslation } from 'react-i18next';


const ComingSoon = () => {
  const { t } = useTranslation('global');
  return (
    <div className='py-32 h-screen w-screen flex flex-col justify-center items-center'>
      <img src={InProgres} alt='coming soon' className='w-80 h-auto lg:w-1/3' />
      <p className='font-bold text-4xl lg:text-8xl'>{t('comingsoon')}</p>
    </div>
  )
}

export default ComingSoon
