import React from 'react'
import Layout from '../../components/layout'

const PrivacyAndPolicy = () => {
  return (
    <Layout disableform classes={'bg-about'}>
      <div className='flex flex-col py-40 justify-center items-center'>
        <div className='lg:w-2/6 w-5/6 md:w-4/6'>
          <div className='flex flex-col gap-3 mb-8'>
            <h1 className='font-extrabold text-4xl'>POLÍTICA DE PRIVACIDAD Y DE COOKIES</h1>
            <p>Estamos comprometidos con la protección de su privacidad y de sus datos personales. La presente Política de Privacidad y de Cookies (la "Política") establece y describe cómo se procesarán sus datos personales en el Sitio Web de Glicorsa (en adelante, el "Sitio").</p>
            <p>A los fines de la presente Política, los términos "nosotros"/"nos"/"nuestro" referirán a los responsables del tratamiento de datos definidos debajo.</p>
          </div>
          <div className='flex flex-col gap-4'>
            <div id='terminos_1' className='flex flex-col gap-4 mb-8'>
              <h2 className='font-bold text-2xl'>MODIFICACIONES</h2>
              <p>Podremos actualizar esta Política de tanto en tanto al publicar una nueva versión de la misma en nuestro Sitio. Sugerimos que consulte periódicamente el Sitio para asegurarse de estar informado de dichas modificaciones.</p>
              <p>Sin perjuicio de ello, le informaremos de cualquier cambio sustancial, ya sea a través de un aviso en el Sitio que resuma los principales cambios realizados, o bien a través de correo electrónico en caso contemos con dicho dato.</p>
              <p>En caso de tener dudas sobre la presente Política o sobre el procesamiento de sus datos personales, usted puede:</p>
              <ul>
                <li> - contactarse con nosotros a traves del <a href='/contact' className='underline'>formulario de contacto</a></li>
              </ul>
            </div>

            <div id='terminos_2' className='flex flex-col gap-4 mb-8'>
              <h2 className='font-bold text-2xl'>¿QUÉ DATOS RECOLECTAMOS?</h2>
              <ul>
                <li>
                  <p>Datos personales que usted nos provee personalmente:</p>
                  <p>Tales como Nombre, telefono, email, empresa</p>
                </li>
              </ul>
            </div>


            <div id='terminos_3' className='flex flex-col gap-2 mb-8'>
              <h2 className='font-bold text-2xl'>PROTECCIÓN DE MENORES DE EDAD</h2>
              <p>El acceso al Sitio está sujeto a la edad de los usuarios y se rige por los <a href="/legal/terms-and-conditions-of-use" className='underline'>Términos y Condiciones del Sitio</a> . Para acceder al Sitio, los usuarios deben tener, como mínimo, la edad legal requerida en su lugar de residencia para consumir bedidas alcóholicas y/o comprar las mismas. Por ello, no recopilamos intencionalmente ningún dato personal de personas que no tengan la edad legal mínima requerida para consumir bebidas alcohólicas y/o comprar las mismas según la legislación vigente en su lugar de residencia, a menos que lo exija o permita la legislación aplicable.</p>
              <p>Para poder realizar compras o cualquier otra transacción disponible en el Sitio, Usted debe tener, al menos, la edad legal mínima requerida por la legislación de su lugar de residencia para consumir bebidas alcóholicas y/o comprarlas. Al realizar transacciones con nosotros, usted afirma que tiene la edad legal requerida y que está plenamente capacitado para realizar dichas transacciones y quedar legalmente vinculado por ellas.</p>
              <p>Sin embargo, si usted cree que podríamos estar tratando datos de personas menores de edad que no cuenten con la edad mínima requerida para consumir o comprar bebidas alcóholicas, por favor contactenos para que podamos eliminar la misma, en cumplimiento con las normas aplicables.</p>
            </div>

            <div id='terminos_4' className='flex flex-col gap-2 mb-8'>
              <h2 className='font-bold text-2xl'>POLÍTICA DE COOKIES</h2>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>COOKIES - ¿QUÉ SON?</h3>
                <p>Una "cookie" es un fragmento de información, como una etiqueta, que se almacena en su computadora, tablet o celular cuando visita un sitio web. Puede ayudar a identificar su dispositivo (como su computadora o teléfono móvil) cada vez que visita ese sitio web.</p>
                <p>La mayoría de los principales sitios web, incluido el nuestro, utilizan cookies. Para hacer el mejor uso de nuestro Sitio, en cualquier dispositivo que utilice, deberá asegurarse de que su navegador web esté configurado para aceptar cookies.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>¿PARA QUÉ SE UTILIZAN LAS COOKIES? </h3>
                <p>Utilizamos cookies y otros datos almacenados en su dispositivo para:</p>
                <ul>
                  <li>Ofrecerle una mejor experiencia en línea mostrando un contenido editorial personalizado basado en lo que ha visto anteriormente.</li>
                  <li>Permitirle establecer preferencias personales (recordar sus datos de acceso, elegir un país y un idioma, añadir artículos a su carrito).</li>
                  <li>Proteger su seguridad.</li>
                  <li>Medir y mejorar nuestro servicio.</li>
                  <li>Trabajar con socios y medir la audiencia.</li>
                </ul>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>¿CÓMO PUEDO GESTIONAR LAS COOKIES INSTALADAS EN MI COMPUTADORA O CELULAR?</h3>
                <p>Puede aceptar o rechazar las distintas categorías de cookies haciendo click en Configuración de cookies en el pie de la pagina.</p>
              </div>
              <div className='flex flex-col gap-2'>
                <h3 className='font-semibold text-xl'>¿QUÉ SUCEDE SI NO ACEPTO LAS COOKIES?</h3>
                <p>Si rechaza las cookies, algunos aspecto del Sitio no funcionarán en su computadora o celular y no podrá acceder a ciertas áreas del Sitio.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyAndPolicy
