import React, { useEffect, useRef } from 'react'
import Layout from '../../components/layout'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import { motion, useInView, useAnimation } from "framer-motion";

import VelocityLayout from '../../components/hero-velocity';
import ButtonRedirect from '../../components/ui/button-redirect';
import { HomeImage2, HomeImage3, HomeImage4, HomeImage5 } from '../../assets/company';
import { Laboratori1, Services1, Services3, Services4 } from '../../assets/services';
import { LogoVelocity } from '../../assets/liquors/velocity';
import { LogoWarner } from '../../assets/liquors/warner';
import { LogoMasterfino } from '../../assets/liquors/master';

import useSEO from '../../hooks/useSEO';
import ImageCollage from '../../components/ui/image-collage';





const HomeView = () => {
  const { t } = useTranslation('global');
  const velocityRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const productsRef = useRef(null);
  const visionMisionRef = useRef(null);

  const mainControls = useAnimation();

  const velocityInView = useInView(velocityRef, { once: true });
  const aboutInView = useInView(aboutRef, { once: true });
  const servicesInView = useInView(servicesRef, { once: true });
  const productsInView = useInView(productsRef, { once: true });
  const visionMisionInView = useInView(visionMisionRef, { once: true });

  useEffect(() => {
    if (velocityInView) {
      mainControls.start({ opacity: 1 });
    }
    if (aboutInView) {
      mainControls.start({ opacity: 1, y: 0 });
    }
    if (servicesInView) {
      mainControls.start({ opacity: 1, y: 0 });
    }
    if (productsInView) {
      mainControls.start({ opacity: 1, y: 0 });
    }
    if (visionMisionInView) {
      mainControls.start({ opacity: 1, y: 0 });
    }
  }, [velocityInView, aboutInView, servicesInView, productsInView, visionMisionInView, mainControls]);

  useSEO({
    description: `Welcome to the home page of Embotelladora Global de Licores, Glicorsa SA de CV. Explore our world of premium spirits, where craftsmanship meets innovation. Discover exceptional flavors and quality that define our commitment to excellence.`,
    title: 'Glicorsa | Home - Crafted Excellence in Premium Spirits'
  })


  return (
    <Layout classes={`bg-inprogres `} textwhite={true}>

      <VelocityLayout />

      <div id='velocity' className='min-h-screen flex flex-col items-center justify-center pt-40 '>
        <motion.div
          className='xl:w-5/6 lg:items-start w-3/4 lg:w-4/6 flex flex-col p-2 '
          ref={velocityRef}
          animate={mainControls}
          initial={{ opacity: 0 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: .25
          }}>
          <div className='text-start lg:text-4xl text-2xl  font-semibold '> {t('home.velocity.title')} </div>
          <div className='text-start lg:text-2xl text-xl italic mb-2'> {t('home.velocity.subtitle')} </div>
          <div className='mb-8 text-justify lg:text-xl'> {t('products.velocity.lore')} </div>
          <ButtonRedirect href={'/products/velocity'} text={'Conocer mas'} />
        </motion.div>
      </div>

      <div id='About_us' className='w-full p-7 min-h-screen flex items-center'>
        <motion.div
          initial={{ opacity: 0, y: '20%' }}
          ref={aboutRef}
          animate={mainControls}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: .25
          }}
          className='flex flex-col lg:flex-row items-center justify-center gap-16'>
          <div className='lg:w-1/2 w-5/6 lg:items-end lg:h-3/4 flex flex-col'>
            <div className='text-justify text-2xl lg:text-6xl font-semibold '> {t('home.about.title')} </div>
            <div className='text-justify text-xl lg:text-2xl italic mb-2'> {t('home.about.subtitle')} </div>
            <div className='mb-8 lg:text-end lg:text-xl'> {t('home.about.text')} </div>
            <div className='mb-8 lg:text-end lg:text-xl'> {t('about.target.text')} </div>
            <ButtonRedirect href={'/aboutus'} text={'Conocer mas'} />
          </div>
          <ImageCollage upright={HomeImage2} upleft={HomeImage4} downright={HomeImage5} downleft={HomeImage3} />
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0, y: '20%' }}
        ref={servicesRef}
        animate={mainControls}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          delay: .25
        }}
        id='services' className='p-7 items-center flex-col lg:flex-row flex min-h-screen gap-16'>
        <ImageCollage upright={Services1} upleft={Laboratori1} downright={Services3} downleft={Services4} />
        <div className='lg:w-1/2 w-5/6 lg:items-start lg:h-3/4 flex flex-col'>
          <div className='text-justify text-2xl lg:text-6xl font-semibold '> {t('home.services.title')} </div>
          <div className='text-justify text-xl lg:text-2xl italic mb-2'> {t('home.services.subtitle')} </div>
          <div className='mb-6 lg:text-start  lg:text-xl'> {t('home.services.text')} </div>
          <div className='flex flex-col gap-2 mb-6 '>
            <h2 className='text-2xl font-medium text-gray-800'>{t('services.designs.title')}</h2>
            <p className='text-gray-600 text-xl '>
              {t('services.designs.text')}
            </p>
          </div>
          <div className='flex flex-col gap-2 mb-6 '>
            <h2 className='text-2xl font-medium text-gray-800'>{t('services.packaging.title')}</h2>
            <p className='text-gray-600 text-xl '>
              {t('services.packaging.text')}
            </p>
          </div>
          <ButtonRedirect href={'/services'} text={'Conocer mas'} />
        </div>
      </motion.div>

      <motion.div id='products' className='p-7 items-center flex-col flex min-h-[50vh] gap-16'>
        <div className='lg:items-start lg:h-3/4 flex flex-col'>
          <div className='text-justify text-2xl lg:text-6xl font-semibold '> {t('home.products.title')} </div>
          <div className='text-justify text-xl lg:text-2xl italic mb-2'> {t('home.products.subtitle')} </div>
        </div>
        <motion.div
          initial={{ opacity: 0, y: '20%' }}
          ref={productsRef}
          animate={mainControls}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: .25
          }}
          className='lg:items-center lg:h-3/4 flex flex-col lg:flex-row gap-4'>
          <a href='/products/velocity' className='hover:bg-slate-300 rounded-xl duration-300 w-64 h-64 flex items-center justify-center'>
            <img src={LogoVelocity} alt='Velocity Liquor Logo' className='w-64 h-auto' />
          </a>
          <a href='/products/masterfino' className='hover:bg-slate-300 rounded-xl duration-300 w-64 h-64 flex items-center justify-center'>
            <img src={LogoMasterfino} alt='Velocity Liquor Logo' className='w-64 h-auto' />
          </a>
          <a href='/products/warner' className='hover:bg-slate-300 rounded-xl duration-300 w-64 h-64 flex items-center justify-center'>
            <img src={LogoWarner} alt='Velocity Liquor Logo' className='w-64 h-auto' />
          </a>
        </motion.div>
      </motion.div>

      <motion.div id='mision and vision' className='p-7 lg:p-32 flex lg:flex-row flex-col min-h-[50vh] justify-center items-center gap-8'>
        <div className='lg:w-1/2 flex flex-col gap-4  h-96 justify-center'>
          <div className='font-bold text-4xl'>{t('about.missionvision.vision-title')}</div>
          <div className='text-xl'>{t('about.missionvision.vision')}</div>
        </div>
        <div className='lg:w-1/2 flex flex-col gap-4 h-96 justify-center'>
          <div className='font-bold text-4xl'>{t('about.missionvision.mission-title')}</div>
          <div className='text-xl'>{t('about.missionvision.mission')}</div>
        </div>
      </motion.div>
    </Layout>
  )
}

export default HomeView
