import CardNav from "./card";

const CardList = ({ currentPath, items }) => {
  return (
    <ul className='absolute left-0 z-40 text-black p-2 w-full h-[700px]'>
      <div className="w-full h-full flex justify-evenly items-center pt-16">
        <div className="absolute top-[40px] h-full inset-0 z-10 bg-black opacity-90"/>
        {items.map((item, index) => (
          <li key={index} className="z-50">
            <CardNav
              currentPath={currentPath}
              href={item.href}
              text={item.text}
              image={item.image}
              type={item.type}
            />
          </li>
        ))}
      </div>
    </ul>
  );
}

export default CardList;
