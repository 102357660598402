import React from 'react'

import { GlicorsaComplete, GlicorsaCompleteWhite } from '../../assets/logo'

import ButtonLanguaje from '../button-language'
import NavItem from './nav-item'
import { VelocityTequilaBlanco } from '../../assets/liquors/velocity'
import { MasterfinoRonExtraAnejo } from '../../assets/liquors/master'
import { VodkaRainbow } from '../../assets/liquors/warner'


const DesktopNavBar = ({ navItems, currentPath, textwhite }) => {

  const getProductDropdownItems = () => {
    // Lógica para obtener los elementos del dropdown del producto
    return [
      { href: '/products/velocity', text: 'Velocity', image: VelocityTequilaBlanco, type: 'Tequila' },
      { href: '/products/masterfino', text: 'Masterfino', image: MasterfinoRonExtraAnejo, type: 'Ron' },
      { href: '/products/warner', text: 'Warner & Blanc', image: VodkaRainbow, type: 'Vodka' },
      // Agrega más elementos de producto si es necesario
    ];
  };


  return (
    <nav className='hidden xl:flex items-center justify-between'>
      <a
        href='/'
        className='flex justify-start items-end w-1/3 cursor-default'>
        <img
          src={textwhite ? GlicorsaCompleteWhite : GlicorsaComplete}
          alt='logo'
          className={`h-32 cursor-pointer`} />
      </a>

      <ul className='flex space-x-4 items-center justify-center text-xl w-1/3'>
        {navItems.map((item, index) => (
          <NavItem
            key={index}
            href={item.href}
            text={item.text}
            image={item.image}
            type={item.type}
            currentPath={currentPath}
            textwhite={textwhite}
            dropdownItems={item.href === '/products' ? getProductDropdownItems() : null}
          />
        ))}
      </ul>

      <div className='w-1/3 flex justify-end'>
        <ButtonLanguaje />
      </div>
    </nav>
  )
}

export default DesktopNavBar
