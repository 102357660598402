import React, { useEffect, useRef } from 'react'
import { motion, useInView, useAnimation } from "framer-motion";
import { useTranslation } from 'react-i18next';

const ContainerSpecsMasterfino = ({
  aging,
  alcohol,
  background = 'bg-white',
  backgroundSVGBottom = '#ffffff',
  backgroundSVGTop = '#ffffff',
  colorType,
  description,
  id,
  image,
  isRender = false,
  liquor,
  textColor,
  textColorDescription = 'text-white',
  type,
  variantWave = 1,
  vv = false,
}) => {
  const { t } = useTranslation('global');
  const BottleRef = useRef(null);
  const BottleControls = useAnimation()
  const BottleInView = useInView(BottleRef, { once: true });

  useEffect(() => {
    if(BottleInView) BottleControls.start({opacity: 1, x: 0});
  }, [BottleInView, BottleControls])
  return (
    <>
      <motion.div
        id={id}
        className={`
          flex flex-col lg:flex-row p-4
          min-h-screen items-center justify-center
          bg-no-repeat bg-cover bg-center text-white
          ${background}
          `}>
        <motion.div
          initial={{ opacity: 0, x: '-100%' }}
          animate={BottleControls}
          ref={BottleRef}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: 0.25
          }}
          className='lg:w-1/2 pb-16 flex justify-center items-center'>
          <div className='lg:1/2 h-full flex justify-center items-center'>
            <div className='lg:w-1/2 md:w-2/4 w-3/4 h-auto rounded-md '>
              <img src={image} alt={image} className={`${isRender ? 'w-96' : 'object-cover object-center'} rounded-md`} />
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: '-100%' }}
          animate={BottleControls}
          ref={BottleRef}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            delay: 0.25
          }}
          className={`flex flex-col lg:w-1/2 lg:pr-8 ${textColorDescription}`}>
          <motion.div className='items-center w-full'>
            {/* <img src={LogoMasterfino} alt={liquor} className='w-8 lg:w-80 h-fit rounded-md mb-2' /> */}
            <div className={`text-2xl font-bold ${colorType} p-1 w-fit rounded-sm shadow-md ${textColor}`}>{type}</div>
          </motion.div>
          <div className='h-0.5 w-full my-4 bg-slate-400'></div>
          <motion.div className='text-left items-center lg:text-2xl'>
            <div className='mb-2'>
              <span className={`font-bold ${textColor}`}></span> {t(description)}
            </div>
            <div className='mb-2'>
              <span className={`font-bold ${colorType} p-1 w-fit rounded-sm shadow-md  ${textColor}`}>{t('products.liquorSpecTitle.aging')}:</span>{vv && '+'} {t(aging)}
            </div>
            <div className='mb-2'>
              <span className={`font-bold ${colorType} p-1 w-fit rounded-sm shadow-md  ${textColor}`}>{t('products.liquorSpecTitle.alcohol')}:</span> {t(alcohol)}
            </div>
          </motion.div>
        </motion.div>
      </motion.div>
      {variantWave === 1 ?
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill={backgroundSVGTop} fillOpacity="1" d="M0,96L34.3,90.7C68.6,85,137,75,206,80C274.3,85,343,107,411,138.7C480,171,549,213,617,192C685.7,171,754,85,823,48C891.4,11,960,21,1029,42.7C1097.1,64,1166,96,1234,101.3C1302.9,107,1371,85,1406,74.7L1440,64L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
            <path fill={backgroundSVGBottom} fillOpacity="1" d="M0,96L34.3,90.7C68.6,85,137,75,206,80C274.3,85,343,107,411,138.7C480,171,549,213,617,192C685.7,171,754,85,823,48C891.4,11,960,21,1029,42.7C1097.1,64,1166,96,1234,101.3C1302.9,107,1371,85,1406,74.7L1440,64L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          </svg>
        </div>
        :
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill={backgroundSVGTop} fillOpacity="1" d="M0,224L34.3,186.7C68.6,149,137,75,206,90.7C274.3,107,343,213,411,224C480,235,549,149,617,138.7C685.7,128,754,192,823,229.3C891.4,267,960,277,1029,256C1097.1,235,1166,181,1234,176C1302.9,171,1371,213,1406,234.7L1440,256L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
            <path fill={backgroundSVGBottom} fillOpacity="1" d="M0,224L34.3,186.7C68.6,149,137,75,206,90.7C274.3,107,343,213,411,224C480,235,549,149,617,138.7C685.7,128,754,192,823,229.3C891.4,267,960,277,1029,256C1097.1,235,1166,181,1234,176C1302.9,171,1371,213,1406,234.7L1440,256L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
          </svg>
        </div>
      }
    </>
  );
};

export default ContainerSpecsMasterfino;
