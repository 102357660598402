import React from 'react'
import Layout from '../../../components/layout'
import useSEO from '../../../hooks/useSEO';
import MasterfinoSpecs from '../../../components/specs-masterfino';
import { motion } from 'framer-motion';
import { Link } from 'react-scroll'



const MasterfinoView = () => {

  useSEO({
    description: `Discover the exquisite flavors of Masterfino Ron, an exceptional rum crafted by Glicorsa. Immerse yourself in the rich and refined taste of our premium rum, a true masterpiece in every sip.`,
    title: 'Glicorsa | Masterfino Ron'
  })


  return (
    <Layout classes={`bg-white `}>
      <div
        className={`
          min-h-screen
          flex flex-col items-center pt-40
          bg-cover bg-no-repeat bg-center
          bg-hero-master
        `}></div>
      <motion.div className='bg-cover bg-no-repeat bg-center flex flex-wrap justify-center items-center gap-8 pt-24 px-1'>
        <Link to='MasterAnejo' duration={500} offset={20} smooth spy className='lg:text-4xl text-2xl font-bold bg-master_anejo_primary text-master_anejo_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Añejo</Link>
        <Link to='extraAnejo' duration={500} offset={20} smooth spy className='lg:text-4xl text-2xl font-bold bg-master_extraanejo_primary text-master_extraanejo_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Extra Añejo</Link>
        <Link to='extraAnejoVV' duration={500} offset={20} smooth spy className='lg:text-4xl text-2xl font-bold bg-master_extraanejoVV_primary text-master_extraanejoVV_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Extra Añejo VV</Link>
        <Link to='Masterspiced' duration={500} offset={20} smooth spy className='lg:text-4xl text-2xl font-bold bg-master_spiced_primary text-master_spiced_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Spiced</Link>
        <Link to='MasterBlanco' duration={500} offset={20} smooth spy className='lg:text-4xl text-2xl font-bold bg-master_blanco_primary text-master_blanco_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Blanco</Link>
        <Link to='Mastertropical' duration={500} offset={20} smooth spy className=' lg:text-4xl text-2xl font-bold bg-master_tropical_primary text-master_tropical_secondary p-1.5 rounded-sm w-fit hover:opacity-80 duration-200 shadow-md hover:shadow-none'>Tropical</Link>
      </motion.div>
      <MasterfinoSpecs/>
    </Layout>
  )
}

export default MasterfinoView
