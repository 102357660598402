import React from 'react'
import Layout from '../../components/layout'
import useSEO from '../../hooks/useSEO'

const NotFoundView = () => {
  useSEO({
    description: `Explore the world of Embotelladora Global de Licores, Glicorsa SA de CV. While the requested page may not be found (Error 404), we invite you to discover our range of premium spirits and services. Navigate through our offerings and let us elevate your spirits experience.`,
    title: 'Glicorsa | Error 404 - Explore Premium Spirits and Services'
  })

  return (
    <Layout disableform={true}>
      <div className='w-screen h-screen flex flex-col justify-center items-center'>
        <h2 className='font-bold lg:text-8xl text-4xl'>404 - Not Found</h2>
        <p>The page you are looking for does not exist.</p>
      </div>
    </Layout>
  )
}

export default NotFoundView
