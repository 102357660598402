import React from 'react'
import Layout from '../../../components/layout'
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import VelocitySpecs from '../../../components/spects-velocity';
import { Link } from 'react-scroll'
import useSEO from '../../../hooks/useSEO';
import VelocityLayout from '../../../components/hero-velocity';
import ExportTable from '../../../components/ui/export-table';
import { VelocityLogoHorizontalWhite } from '../../../assets/liquors/velocity';


const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};
const VelocityView = () => {
  const { t } = useTranslation('global');
  useSEO({
    description: `Embark on a journey of taste with Velocity Tequila from Embotelladora Global de Licores, Glicorsa SA de CV. Elevate your drinking experience with the bold and distinctive flavors of our premium tequila.`,
    title: 'Glicorsa | Velocity Tequila'
  })


  return (
    <Layout
      classes={`bg-gradient-to-t from-vegasbackground to-black `}
      textwhite={true}
      footerTextWhite={true}
    >
      <VelocityLayout />

      <motion.div className='flex justify-center bg-vegasbackground bg-cover bg-no-repeat bg-center flex-col lg:flex-row items-center min-h-screen'>
        <motion.div
          variants={icon}
          initial="hidden"
          animate="visible"
          whileInView={{ opacity: 1 }}
          transition={{
            default: { duration: 2, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] }
          }}
          className='text-justify p-6 lg:w-4/6 lg:text-3xl text-white'>

          <motion.div>{t('products.velocity.lore')}</motion.div>
        </motion.div>
      </motion.div>

      <motion.div className='bg-vegasbackground bg-no-repeat bg-center flex flex-wrap justify-center items-center gap-8 pb-16 px-1'>
        <Link to='VelocityLiquorBlanco' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-velocityBlancoType p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Blanco</Link>
        <Link to='VelocityLiquorReposado' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-velocityReposadoType p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Reposado</Link>
        <Link to='VelocityLiquorAnejo' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-velocityAnejoType p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Añejo</Link>
        <Link to='VelocityLiquorExtraAnejo' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-velocityExtraAnejoType p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Extra Añejo</Link>
        <Link to='VelocityLiquorAnejoVV' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-white p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Extra Añejo VV</Link>
        <Link to='VelocityLiquorCristalino' duration={500} offset={20} smooth spy className='text-black lg:text-4xl text-2xl font-bold bg-velocityCristalinoType p-1 rounded-sm w-fit hover:opacity-80 duration-200'>Cristalino</Link>
      </motion.div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill={'#000017'} fillOpacity="1" d="M0,224L34.3,186.7C68.6,149,137,75,206,90.7C274.3,107,343,213,411,224C480,235,549,149,617,138.7C685.7,128,754,192,823,229.3C891.4,267,960,277,1029,256C1097.1,235,1166,181,1234,176C1302.9,171,1371,213,1406,234.7L1440,256L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path>
          <path fill={'#8eabbf'} fillOpacity="1" d="M0,224L34.3,186.7C68.6,149,137,75,206,90.7C274.3,107,343,213,411,224C480,235,549,149,617,138.7C685.7,128,754,192,823,229.3C891.4,267,960,277,1029,256C1097.1,235,1166,181,1234,176C1302.9,171,1371,213,1406,234.7L1440,256L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path>
        </svg>
      </div>

      <VelocitySpecs />

      <motion.div
        className='w-full flex flex-col items-center lg:text-xl gap-8 text-white min-h-screen justify-center'>
        <div className='flex flex-row items-end gap-4 pb-16 text-3xl font-bold'>
          {t('services.export.title')}
        </div>
        <div className='w-full flex lg:flex-row flex-col justify-evenly items-center gap-8 text-xl lg:text-2xl'>
          <ExportTable
            bottlesxbox={'6'}
            boxesxPallet={'80'}
            palletesxContainer={'26'}
            totalBoxes={'2,080'}
            totalBottles={'12,480'}
            ml={'750ml'}
            image={VelocityLogoHorizontalWhite}
          />
          <ExportTable
            bottlesxbox={'6'}
            boxesxPallet={'66'}
            palletesxContainer={'26'}
            totalBoxes={'1,716'}
            totalBottles={'10,296'}
            ml={'1L'}
            image={VelocityLogoHorizontalWhite}
          />
        </div>
      </motion.div>
    </Layout>
  )
}

export default VelocityView
