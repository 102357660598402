import React, { useEffect, useState } from 'react';

import ButtonLanguaje from '../button-language';
import NavItem from './nav-item';

import { HamburgerIcon, HamburgerIconWhite } from '../../assets/navbar';

const MobileNavBar = ({
  currentPath,
  navItems,
  menuVisible,
  toggleMenu,
  textwhite
}) => {

  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  if (!isMounted) { return null }

  return (
    <div className={`xl:hidden ${menuVisible ? 'w-full' : 'w-fit'}`}>
      <div role="button" onClick={toggleMenu}>
        <img src={textwhite ? HamburgerIconWhite : HamburgerIcon} alt="menu" className="w-6 h-auto" />
      </div>
      <div
        className={`xl:hidden z-50 w-full animate-fade animate-duration-700 p-1
        ${menuVisible ? '' : 'hidden'}`}
      >
        <div className={`xl:block ${menuVisible ? 'translate-x-0' : ''} duration-500 flex items-center justify-between`}>
          <ul className="flex flex-col items-start text-xl">
            {navItems.map((item, index) => (
              <NavItem key={index} href={item.href} text={item.text} currentPath={currentPath} textwhite={textwhite} />
            ))}
          </ul>
          <div className="p-7">
            <ButtonLanguaje />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileNavBar;
